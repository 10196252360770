var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5dadd28cbe4c3d035cdf4f38ebd11f0082277775_0.82.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { init, Replay } from '@sentry/nextjs'

import { ENV_NAME, REACT_APP_VERSION, SENTRY_DSN } from './src/config'

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

init({
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  dsn: SENTRY_DSN,
  environment: ENV_NAME,
  integrations: [new Replay()],
  release: REACT_APP_VERSION,
  replaysOnErrorSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 1.0,
})
